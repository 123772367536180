import { isNullOrUndefined, format } from "util";
import {
  SessionStorageService,
  LocalStorageService,
} from "angular-web-storage";
import { DatePipe } from "@angular/common";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";

export class AppConstants {
  // ********* Local Path *********/
  //   public static BasePath = 'http://localhost:8002/';
  // public static BasePath = "http://192.168.0.143:8002/";
  // public static BasePath = '/api/';

  // ********* Cloud Path - Prod **********/
  public static BasePath = "https://ahcv.myislandhr.com/api/";
  // public static BasePath = 'https://ahcv.myislandhr.com/api/';

  // ********* Cloud Path - Test **********/
  // public static BasePath = "https://hpstest.myislandhr.com/testapi/";

  // ********* Cloud Path - Prod Test**********/
  //public static BasePath = 'https://ahcvtest.myislandhr.com/clienttestapi/';
}

export class IsLoggedIn {
  SessionStorage: any;
  public IsUserLoggedIn() {
    this.SessionStorage = new SessionStorageService();
    const Value = this.SessionStorage.get("IsLoggedIn");
    // tslint:disable-next-line: triple-equals
    if (isNullOrUndefined(Value) || !Value || Value == false) {
      return false;
    }
    return true;
  }
}

export class Pagination {
  maxSize: number;
  TotalItems: number;
  CurrentPage: number;
}

export class SearchResultCount {
  fromcount: any;
  tocount: any;
  total: any;

  UpdateSearchResultCount(pagination: any, searchResultLength: any) {
    this.fromcount = pagination.CurrentPage;
    if (pagination.CurrentPage > 1) {
      this.fromcount = (pagination.CurrentPage - 1) * 10 + 1;
    }
    this.tocount = this.fromcount;
    this.total = pagination.TotalItems;
    if (searchResultLength > 0) {
      this.tocount = this.fromcount + (searchResultLength - 1);
      return (
        "Showing " +
        this.fromcount +
        " - " +
        this.tocount +
        " of " +
        this.total +
        " results"
      );
    } else {
      return null;
    }
  }

  UpdateSearchResultCountForAdmin(
    pagination: any,
    searchResultLength: any,
    rowsize: number
  ) {
    this.fromcount = pagination.CurrentPage;
    if (pagination.CurrentPage > 1) {
      this.fromcount = (pagination.CurrentPage - 1) * rowsize + 1;
    }
    this.tocount = this.fromcount;
    this.total = pagination.TotalItems;
    if (searchResultLength > 0) {
      this.tocount = this.fromcount + (searchResultLength - 1);
      return (
        "Showing " +
        this.fromcount +
        " - " +
        this.tocount +
        " of " +
        this.total +
        " results"
      );
    } else {
      return null;
    }
  }
}

export class ErrorMsg {
  public static ErrorMessage = "Error has been occured,please try again later.";
}

export class ErrorMsgTab {
  MessageDescription: string;
  Type: string;
  Date: any;
}

export class TotalWorkingHrs {
  TotalWorkingHrs: string = "8:30";
}

export class UnPaidLeave {
  UnPaidLeave: string = "Un-Paid Leave";
}

export class FormatDate {
  UIDate: any;
  public datepipe: DatePipe;
  public FormatDate(date: string): any {
    if (date != "" && !isNullOrUndefined(date)) {
      let Dates = date;
      let val: any;
      val = Dates.split("T", 2);
      val = val[0];
      let splitddmmyy = val.split("-", 3);
      let dd = splitddmmyy[2];
      let mm = splitddmmyy[1];
      let yy = splitddmmyy[0];
      let adddata = dd + "-" + mm + "-" + yy;
      let finalVal = adddata;
      return finalVal;
    }
    return null;
  }

  SetUIDateToDate(date: any, Formatter: NgbDateParserFormatter) {
    let FormatDate = null;
    if (!isNullOrUndefined(date) && date != "") {
      if (
        date.day != null &&
        date.day != "" &&
        date.month != null &&
        date.month != "" &&
        date.year != null &&
        date.year != ""
      ) {
        this.UIDate = date;
        let Dates = Formatter.format(this.UIDate);
        let splitddmmyy = Dates.split("-", 3);
        let Year = splitddmmyy[2];
        let Month = splitddmmyy[1];
        let Day = splitddmmyy[0];
        FormatDate = Year + "-" + Month + "-" + Day;
        return FormatDate;
      } else {
        return FormatDate;
      }
    } else {
      return FormatDate;
    }
  }

  GetPreviousDateTime(Mydate: any): any {
    if (!isNullOrUndefined(Mydate) && Mydate != "") {
      {
        let times = "00:00";
        let today;
        var myDate = new Date(Mydate);
        console.log(myDate.toDateString());
        var previousDay = new Date(myDate);
        previousDay.setDate(myDate.getDate() - 1);
        console.log(previousDay);
        let date = previousDay.getDate();
        let month = previousDay.getMonth();
        let year = previousDay.getFullYear();
        if (date < 10) {
          var dd = "0" + format(date);
        } else {
          var dd = format(date);
        }
        if (month <= 8) {
          let Month = month + 1;
          var mm = "0" + format(Month);
        } else {
          let Month = month + 1;
          var mm = format(Month);
        }
        let yyyy = format(year);
        today = <string>yyyy + "-" + <string>mm + "-" + <string>dd;
        let Convertedtime: any = <string>today + "T" + <string>times;
        return Convertedtime;
      }
    } else {
      let Convertedtime = null;
      return Convertedtime;
    }
  }

  GetNextDateTime(Mydate: any): any {
    if (!isNullOrUndefined(Mydate) && Mydate != "") {
      {
        let times = "00:00";
        let today;
        var myDate = new Date(Mydate);
        console.log(myDate.toDateString());
        var previousDay = new Date(myDate);
        previousDay.setDate(myDate.getDate() + 1);
        console.log(previousDay);
        let date = previousDay.getDate();
        let month = previousDay.getMonth();
        let year = previousDay.getFullYear();
        if (date < 10) {
          var dd = "0" + format(date);
        } else {
          var dd = format(date);
        }
        if (month <= 8) {
          let Month = month + 1;
          var mm = "0" + format(Month);
        } else {
          let Month = month + 1;
          var mm = format(Month);
        }
        let yyyy = format(year);
        today = <string>yyyy + "-" + <string>mm + "-" + <string>dd;
        let Convertedtime: any = <string>today + "T" + <string>times;
        return Convertedtime;
      }
    } else {
      let Convertedtime = null;
      return Convertedtime;
    }
  }

  GetCurrentDateInString(): any {
    let times = "00:00";
    let today;
    var Currentdate = new Date();
    let date = Currentdate.getDate();
    let month = Currentdate.getMonth() + 1;
    let year = Currentdate.getFullYear();
    if (date < 10) {
      var dd = "0" + format(date);
    } else {
      var dd = format(date);
    }
    if (month <= 8) {
      let Month = month + 1;
      var mm = "0" + format(Month);
    } else {
      let Month = month + 1;
      var mm = format(Month);
    }
    let yyyy = format(year);
    today = <string>yyyy + "-" + <string>mm + "-" + <string>dd;
    let Convertedtime: any = <string>today + "T" + <string>times;
    return Convertedtime;
  }

  SetUIDateToDateAddOneDate(date: any, Formatter: NgbDateParserFormatter) {
    let FormatDate = null;
    if (!isNullOrUndefined(date) && date != "") {
      if (
        date.day != null &&
        date.day != "" &&
        date.month != null &&
        date.month != "" &&
        date.year != null &&
        date.year != ""
      ) {
        this.UIDate = date;
        let Dates = Formatter.format(this.UIDate);
        let splitddmmyy = Dates.split("-", 3);
        let Year = splitddmmyy[2];
        let Year1 = parseInt(Year);
        let Month = splitddmmyy[1];
        let month1 = parseInt(Month) + 1;
        let Day = splitddmmyy[0];
        let Day1 = parseInt(Day) + 1;
        FormatDate = Year1 + "-" + month1 + "-" + Day1;
        return FormatDate;
      } else {
        return FormatDate;
      }
    } else {
      return FormatDate;
    }
  }
}

export class FileFilter {
  //File Format Filter
  public FileFormatFilter(file: File): boolean {
    let LocalStorage = new LocalStorageService();
    if (!isNullOrUndefined(file)) {
      let FileName = file.name;
      let fileSplit = FileName.split(".");
      let fileExtension = fileSplit[fileSplit.length - 1];
      let allowedFormats = LocalStorage.get("attachmentTypes");
      if (!isNullOrUndefined(allowedFormats)) {
        if (allowedFormats.search(fileExtension) == -1) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      console.log("File undefined on File Formatter");
    }
  }

  //File Size Filter
  public FileSizeFilter(file: File): boolean {
    let LocalStorage = new LocalStorageService();
    let maxSize: number;
    if (!isNullOrUndefined(file)) {
      maxSize = parseInt(LocalStorage.get("attachmentSize"));
      let fileSize = file.size / (1000 * 1000); //Converting Size from Bytes to Megabytes(MB)

      if (fileSize > 0) {
        if (fileSize < maxSize) {
          return true;
        } else {
          return false;
        }
      } else {
        console.log("File Size is undefined..");
        return false;
      }
    } else {
      console.log("File Type is undefined..");
      return false;
    }
  }
}
